<template>
  <div class="pa-7">
    <v-row>
      <v-btn
        class="no-space mb-6"
        @click="$emit('terminateSearch')"
        icon>
        <v-icon color="primaryColor">mdi-arrow-left</v-icon>
      </v-btn>
    </v-row>

    <!-- search input -->
    <v-row
      justify="space-between"
      align="center"
      class="mb-6"
      no-gutters>
      <v-col cols="11">
        <v-text-field
          id="search-input"
          prepend-inner-icon="mdi-magnify"
          hide-details
          filled
          rounded
          dense
          color="brandGrey1"
          class="pa-0"
          clearable
          clear-icon="mdi-close-circle-outline"
          v-model="searchInput">
        </v-text-field>
      </v-col>

      <v-col cols="1">
        <v-btn
          @click="openCourseFiltersDialog"
          icon>
          <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
        </v-btn>
      </v-col>
    </v-row>
    <!-- show courses based on keyword and filters-->
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primaryColor">
    </v-progress-linear>
    <template v-else>
      <div
        v-if="noCoursesMatchSearch"
        class="empty-search brandGrey2--text font-weight-medium text-center text-uppercase d-flex justify-center align-center">
        YOUR SEARCH '{{ searchInput }}' DID NOT MATCH ANY RESULTS
      </div>

      <div v-else>
        <div
          v-for="course in filteredCourses"
          :key="course.id">
          <CourseRowCard :course="course" />
        </div>
      </div>
    </template>
    <v-bottom-sheet
      v-model="showCourseFiltersDialog"
      bottom>
      <course-filters-dialog
        :mediaType="mediaType"
        :maxTime="maxTime"
        @closeDialog="closeCourseFiltersDialog"
        @setMediaType="setMediaType"
        @setMaxTime="setMaxTime"
        @filterCourses="setFilterCourses(courses, searchInput, 'filter', true)"
        @clearAllFilters="setFilterCourses(courses, searchInput, 'clear')" />
    </v-bottom-sheet>
  </div>
</template>

<script>
  import CoursesSearchMixin from '@/components/courses/courses-search/CoursesSearch.mixin.vue';
  export default {
    mixins: [CoursesSearchMixin],
  };
</script>

<style lang="scss" scoped>
  .empty-search {
    height: 300px;
    letter-spacing: 0.15rem;
    line-height: 21px;
  }
</style>
